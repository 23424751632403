$icons: (
	"arrow-left": (
		width: 250,
		height: 490
	),
	"bike-black": (
		width: 100,
		height: 100
	),
	"bike-black-small": (
		width: 48,
		height: 48
	),
	"bike-white-small": (
		width: 48,
		height: 48
	),
	"car-black": (
		width: 100,
		height: 100
	),
	"car-black-small": (
		width: 48,
		height: 48
	),
	"car-white-small": (
		width: 48,
		height: 48
	),
	"chevron-down": (
		width: 64,
		height: 32
	),
	"chevron-down-light": (
		width: 64,
		height: 32
	),
	"chevron-left": (
		width: 32,
		height: 64
	),
	"chevron-right": (
		width: 32,
		height: 64
	),
	"chevron-small-down": (
		width: 64,
		height: 32
	),
	"chevron-small-up": (
		width: 64,
		height: 32
	),
	"chevron-up": (
		width: 64,
		height: 32
	),
	"chevron-up-light": (
		width: 64,
		height: 32
	),
	"close-modal": (
		width: 37.889,
		height: 37.657
	),
	"close-button": (
		width: 38,
		height: 38
	),
	"hamburger": (
		width: 38,
		height: 38
	),
	"logo-wittler": (
		width: 260,
		height: 180
	),
	"logo-wittler-white": (
		width: 260,
		height: 180
	),
	"train-black": (
		width: 100,
		height: 100
	),
	"train-black-small": (
		width: 48,
		height: 48
	),
	"train-white-small": (
		width: 48,
		height: 48
	),
	"walk-black": (
		width: 100,
		height: 100
	),
	"walk-black-small": (
		width: 48,
		height: 48
	),
	"walk-white-small": (
		width: 48,
		height: 48
	),
	"ws-icon-blau": (
		width: 800,
		height: 800
	),
	"ws-wortbild": (
		width: 320,
		height: 83
	)
);

@mixin svg($name, $size: 1, $width: auto, $height: auto){
		$icon: map-get($icons, $name);
		$sizeFactor: $size;
		@if $width != auto {
			$sizeFactor: (1 / map-get($icon, width) * $width);
		}
		@else if $height != auto {
			$sizeFactor: (1 / map-get($icon, height) * $height);
		}
		@if $width == auto {
			$width: (map-get($icon, width) * $sizeFactor);
		}
		@if $height == auto {
			$height: (map-get($icon, height) * $sizeFactor);
		}
		display: inline-block;
		background-image: url(../img/svg/cleaned/#{$name}.svg);
	  background-repeat: no-repeat;
		background-position: center center;
		width: #{$width}px;
		height: #{$height}px;
}
