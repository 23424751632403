
// FONTS
$fontMain:   											'Montserrat', sans-serif;
$fontSecond:											$fontMain;
$fontThird:												$fontMain;

// GRAYS
$white:    												#fff;
$gray-25: 												#f5f5f5;
$gray-50: 												#f2f2f2;
$gray-100:												#ececec;
$gray-200:												#d9d9d9;
$gray-300:												#c6c6c5;
$gray-400:												#b1b1b1;
$gray-500:												#9c9b9b;
$gray-600:												#868686;
$gray-700:												#6f6f6e;
$gray-800:												#575756;
$gray-900:												#3c3c3b;
$black:   												#1d282c;

$primary: 												#64aad2;
$secondary: 											#505a64;
$dark: 														$secondary;
$light: 													$white;

$logo: 														'logo-wittler';
$body-color:      								$dark;
$body-bg:													$gray-100;

$grid-breakpoints: (
      xs: 0,
      sm: 576px,
      md: 768px,
      lg: 992px,
      xl: 1200px,
     xxl: 1400px
);

$container-max-widths: (
      sm: 100%,
      md: 720px,
      lg: 960px,
      xl: 1140px,
     xxl: 1280px
);

$header-height: (
     xs: 60,
     sm: 70,
     md: 80,
     lg: 90,
     xl: 100,
    xxl: 100
);

// Links
//
// Style anchor elements.

$link-color:                              $body-color;
$link-decoration:                         underline;
$link-hover-color:                        darken($link-color, 15%);
$link-hover-decoration:                   underline;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

$enable-caret:              			true;
$enable-rounded:            			true;
$enable-shadows:            			false;
$enable-gradients:          			false;
$enable-transitions:        			true;

$font-family-sans-serif:      		$fontMain;
$font-family-monospace:       		$fontSecond;
$font-family-condensed:       		$fontThird;
$font-family-base:            		$font-family-sans-serif;

$font-size-base:              		1.4rem ; // Assumes the browser default, typically `16px`
$font-size-lg:                		($font-size-base * 1.25);
$font-size-sm:                		($font-size-base * .875);

$h1-font-size:                    $font-size-base * 3.6;
$h2-font-size:                    $font-size-base * 2.5;
$h3-font-size:                    $font-size-base * 1.55;
$h4-font-size:                    $font-size-base * 1.46;
$h5-font-size:                    $font-size-base * 1.25;
$h6-font-size:                    $font-size-base;

$headings-font-family:            null;
$headings-font-weight:            400;
$headings-line-height:            1.2;
$headings-color:                  null;

$display1-size:                   $h1-font-size * 1.75;
$display2-size:                   $h1-font-size * 1.5;
$display3-size:                   $h1-font-size * 1.25;
$display4-size:                   $h1-font-size;

$display1-weight:                 300;
$display2-weight:                 300;
$display3-weight:                 300;
$display4-weight:                 300;
$display-line-height:             $headings-line-height;

$lead-font-size:                  $font-size-base * 1.25;
$lead-font-weight:                300;

// Paginatoin
$pagination-border-color: 				$dark;
$pagination-bg: 									$white;
$pagination-color: 								$gray-500;
$pagination-active-border-color: 	$dark;
$pagination-active-bg: 						$white;
$pagination-active-color: 				$secondary;
$pagination-disabled-bg: 					transparent;
$pagination-disabled-color: 			$gray-200;
$pagination-hover-bg: 						transparent;
$pagination-hover-color: 					$secondary;

$viewport-max-width: 							1800px;
