
body {
	  -webkit-font-smoothing: antialiased;
}

a {
  	color: $link-color;

  	&:hover,
  	&:focus {
  		  color: $link-hover-color;
  	}
}

b,
strong {
    font-weight: 600;
}

ul {
    padding-left: 1rem;
    margin-bottom: 1rem;
    > li {
        margin-left: 0;
        margin-bottom: .25rem;
    }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    hyphens: auto;
}

.display-1,
.display-2,
.display-3,
.display-4 {
    text-transform: uppercase;
}

.lead {
    font-family: $font-family-sans-serif;
    line-height: 1.6;
		margin-bottom: 1.25rem;
		padding-top: 1rem;
}

.page-title,
.item-title {
    text-transform: uppercase;
		font-weight: 300;
}

.item-title {
    font-size: $h1-font-size;
		@include media-breakpoint-down(md) {
				font-size: $h2-font-size;
		}
}

.page-title {
    color: $primary;
		font-size: $h3-font-size;
		&:after {
				content: ' //';
		}
}

.txt-sm,
.article-body {
		// font-size: $font-size-sm;
}
.txt-lg {
		font-size: $font-size-lg;
}
.txt-xs {
		font-size: 12px;
}

.bullist {
		padding-left: $font-size-lg;
}

// .serif {
//     font-family: $font-family-serif !important;
// }
.sans {
    font-family: $font-family-sans-serif !important;
}
.monospace,
.mono {
    font-family: $font-family-monospace !important;
}
.condensed,
.cond {
    font-family: $font-family-condensed !important;
}
