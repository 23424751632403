html {

    font-size: 66.5%;

    @include media-breakpoint-up(sm)   {
        font-size: 66.5%;
    }
    @include media-breakpoint-up(md)   {
        font-size: 68.5%;
    }
    @include media-breakpoint-up(lg)   {
        font-size: 71.5%;
    }
}
.button,
input,
a,
img {
    outline: 0 !important;
}
