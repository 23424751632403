/*
 *  # FONTS
 *  --------------------------  */

$font-path: "../fonts/montserrat/";

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-Italic.eot');
    src: local(''),
				url('#{$font-path}Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}Montserrat-Italic.woff2') format('woff2'),
        url('#{$font-path}Montserrat-Italic.woff') format('woff'),
        url('#{$font-path}Montserrat-Italic.ttf') format('truetype'),
        url('#{$font-path}Montserrat-Italic.svg#Montserrat-Italic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-LightItalic.eot');
    src: local(''),
				url('#{$font-path}Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}Montserrat-LightItalic.woff2') format('woff2'),
        url('#{$font-path}Montserrat-LightItalic.woff') format('woff'),
        url('#{$font-path}Montserrat-LightItalic.ttf') format('truetype'),
        url('#{$font-path}Montserrat-LightItalic.svg#Montserrat-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-SemiBoldItalic.eot');
    src: local(''),
				url('#{$font-path}Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}Montserrat-SemiBoldItalic.woff2') format('woff2'),
        url('#{$font-path}Montserrat-SemiBoldItalic.woff') format('woff'),
        url('#{$font-path}Montserrat-SemiBoldItalic.ttf') format('truetype'),
        url('#{$font-path}Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-SemiBold.eot');
    src: local(''),
				url('#{$font-path}Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}Montserrat-SemiBold.woff2') format('woff2'),
        url('#{$font-path}Montserrat-SemiBold.woff') format('woff'),
        url('#{$font-path}Montserrat-SemiBold.ttf') format('truetype'),
        url('#{$font-path}Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-Regular.eot');
    src: local(''),
				url('#{$font-path}Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}Montserrat-Regular.woff2') format('woff2'),
        url('#{$font-path}Montserrat-Regular.woff') format('woff'),
        url('#{$font-path}Montserrat-Regular.ttf') format('truetype'),
        url('#{$font-path}Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-Light.eot');
    src: local(''),
				url('#{$font-path}Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}Montserrat-Light.woff2') format('woff2'),
        url('#{$font-path}Montserrat-Light.woff') format('woff'),
        url('#{$font-path}Montserrat-Light.ttf') format('truetype'),
        url('#{$font-path}Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
