// CARET
@mixin caret-down ( $width: 6px, $color: rgb(255,255,255)) {
   display: inline-block;
   width: 0;
   height: 0;
   margin-left: -($width * 1);
   margin-top: -($width * .5);
   vertical-align: middle;
   border-top:   $width solid $color;
   border-right: $width solid transparent;
   border-left:  $width solid transparent;
}

@mixin caret-up ( $width: 6px, $color: rgb(255,255,255)) {
   display: inline-block;
   width: 0;
   height: 0;
   margin-left: -($width * 1);
   margin-top: -($width * .5);
   vertical-align: middle;
   border-bottom:  $width solid $color    !important;
   border-right: $width solid transparent !important;
   border-left:  $width solid transparent !important;
}

@mixin kill-tab-focus( ) {
  @include hover-focus() {
    // Default
     outline: 0;
     // WebKit
     outline: 0 none -webkit-focus-ring-color;
     outline-offset: 0px;
     box-shadow: none;
  }
}

/*  ====================================================================== */
/*  SVG BACKGROUNDS
/*
/* ====================================================================== */

@mixin svg-bg( $size: 10px ) {
    background-repeat: repeat;
    background-size: $size $size;
    background-position: center center;
}

// STRIPES
@mixin stripes-h ( $background: transparent, $color: $black, $size: 12px ) {
    background-image: str-replace(url("data:image/svg+xml;encoding=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath fill='#{$color}' d='M12,1H0V0h12V1z M12,3H0v1h12V3z M12,6H0v1h12V6z M12,9H0v1h12V9z'/%3E%3C/svg%3E"), "#", "%23");
    background-color: $background;
    @include svg-bg($size);
}

@mixin bg-crosses ( $background: transparent, $color: $black, $size: 16px ) {
    background-image: str-replace(url("data:image/svg+xml;encoding=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpolygon fill='#{$color}' points='7 3 4 3 4 0 3 0 3 3 0 3 0 4 3 4 3 7 4 7 4 4 7 4 7 3'/%3E%3C/svg%3E"), "#", "%23");
    background-color: $background;
    @include svg-bg($size);
}

@mixin header-bg ( $background: transparent, $color: rgba(#1d282c,.9) ) {
    background-image: str-replace(url("data:image/svg+xml;encoding=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 100'%3E%3Cpath fill='#{$color}' d='M455,78c194.43038,0,241.66083,15,415,15,126.11392,0,238-23,410-23,143.19363,0,320-40,320-40V0H0V100C149,100,269,78,455,78Z'/%3E%3C/svg%3E"), "#", "%23");
    background-color: $background;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
}
