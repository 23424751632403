/*
 *  # HERO
 *  --------------------------  */

.hero {
    position: relative;
    max-width: $viewport-max-width;
    margin: 0 auto;
	  width: 100%;
    overflow: hidden;
    @include media-breakpoint-up(xl)
    {
        // max-height: 750px;
    }

    .layer {
        position: relative;
        .logo {
            position: relative;
            // top: 200px;
            @include media-breakpoint-down(md)
            {
                // top: 100px;
            }
        }
    }
}

.hero-slider-card {
    background-color: rgba($white,.85);
    text-align: left;
}

//  Slider
.slider {
    // text-align: center;
    display: none;
    height: inherit;
    .slick-list,
    .slick-list > .slick-track {
        height: inherit;
    }

    .slick-slide > div {
        line-height: 0;
        font-size: 0;
    }

    &.slick-initialized {
        display: block;
    }

    &.default-slider {
        @include media-breakpoint-down(md) {
            margin-left:  -1rem;
            margin-right: -1rem;
        }
    }
    &.slick-hero {

    }

  	.slick-arrow {
    		position: absolute;
        width: 200px;
        background-color: transparent;
        box-shadow: none;
        @extend .btn;
    		top: 0;
    		bottom: 0;
    		margin: auto;
    		z-index: 2;
        @include kill-tab-focus();

        &:before {
            content: '';
            position: absolute;
            display: block;
            top: 50%;
            padding: 1.25rem 2rem .75rem 2rem;
            transition: opacity .3s linear;
            opacity: 0;
        }

        @include hover() {
            &:before {
                opacity: 1;
            }
        }

        &.slick-prev {
  			    left: 0;
            right: auto;
            &:before {
                @include svg("chevron-left", 1, 'auto', 36);
                left: 18px;
                right: auto;
                margin-top: -18px;
            }
  		  }

    		&.slick-next {
      			right: 0;
            left: auto;
            &:before {
                @include svg("chevron-right", 1, 'auto', 36);
                left: auto;
                right: 18px;
                margin-top: -18px;
            }
    		}
        color: rgba(255,255,255,0) !important;
  	}
}

// Dots
.slick-dotted.slick-slider {

}

.slick-dots {
    position: absolute;
    bottom: 30px;
    list-style: none;
    display: block;
    text-align: center;
    padding-left: 32px;
    padding-right: 32px;
    margin: 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        height: 14px;
        width: 14px;
        margin: 0 5px;

        @include media-breakpoint-down(md)
        {
            height: 10px;
            width:  10px;
            margin: 0 1px;
        }
        padding: 0;
        cursor: pointer;

        button {
            display: block;
            border: 1px solid $primary;
            background-color: rgba($primary,0);
            border-radius: 7px;
            height: 14px;
            width: 14px;

            @include media-breakpoint-down(md)
            {
                border-radius: 5px;
                height: 10px;
                width: 10px;
            }
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 0;

            cursor: pointer;
            z-index: 2;

            &:hover,
            &:focus {
                outline: none;
                background-color: rgba($primary,0);
            }
        }
        &:before {
            content: '';
            position: absolute;
            display: block;
            height: 14px;
            width: 14px;
            border-radius: 7px;
            border: 1px solid rgba($secondary,.5);
            background-color: rgba($secondary,0);
            z-index: 1;
            left: 0;
            right: 0;

            @include media-breakpoint-down(md)
            {
                border-radius: 5px;
                height: 10px;
                width: 10px;
            }
        }
        &.slick-active button {
            background-color: rgba($primary,1);
        }
        &.slick-active:before {
            background-color: rgba($secondary,1);
        }
    }
}
