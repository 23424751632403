/*
 *  APP.SCSS
 *  .dev/scss/app.scss
 *
 *  Main application styles.
 */

/*
 *  # TOC
 *  --------------------------

   # IMPORTS
     - Core variables and mixins
     - Fonts
     - Icons
       - Spinner
   # GLOBAL
   # GLOBAL STRUCTURE
     - .backdrop
     - .page > .main > .section
     - Equalize grid-blog spacing
   # HEADER
     - Header-Container
     - Navbar-Toggle-Button (.navbar-toggler)
     - Logo (.navbar-brand)
   # NAVS
     - Main Navi
     - Dropdown menu
     - Other Navs
       - .project-filter
       - .footer-nav
       - .pagenav
   # BUTTONS
     - Scroll-to-top Button
   # MODULES
     - G-Maps
   # COMPONENTS
     - Blog featured
   # UTILS

 *  --------------------------
 *
 */

 /*
  *  # IMPORTS
  *  --------------------------  */

/* Core variables and mixins */
@import './fonts/fonts';
@import '~bootstrap/scss/functions';
@import 'variables';
@import '~bootstrap';

@import './bootstrap/reset';

/*  Fonts   */

/*  Icons   */

/*  ENTYPO+ ICONS
 *	--------------
 *  @see: 	 /fonts/entypo/
 *	@html:   <span class="icon-[name]"></span>
 *  @mixin:  @include entypo($code, $color);
/*	-----	*/
@import './icons/entypo';
@import './icons/entypo-codes';

// SPINNER
// use w/ entypo icon-class (f.e. .icon-cycle .spinner .spin)
.spin {
   &:before {
      animation: spin 1.5s infinite linear;
      display: inline-block;
   }
}
@keyframes spin {
  0% {
    transform: rotate(359deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.spinner {
    position: absolute;
    &:before {
        display: block;
        position: absolute;
        color: $primary;
        width: 48px;
        height: 48px;
        font-size: 48px;
        margin-left: -24px;
        margin-top: -24px;
        left: 50%;
        top: 50%;
        @include media-breakpoint-down(md) {
            width: 24px;
            height: 24px;
            font-size: 24px;
            margin-left: -12px;
            margin-top: -12px;
        }
    }
}

@import './custom/svgicons';

/*  custom   */
@import './custom/mixins';
@import './custom/type';
@import './lib/slick';
@import './custom/hero';

html,
body {
    overflow-x: hidden;
    background-color: $gray-200;
}

/*
 *  # GLOBAL STRUCTURE
 *  --------------------------  */

.page {
    position: relative;
    max-width: $viewport-max-width;
    margin: 0 auto;
   	width: 100%;
    background-color: $white;
    padding-left: 0;
    padding-right: 0;

    /*
    padding-top: #{map-get($header-height, xs)}px;
    @include media-breakpoint-up(sm) {
        padding-top: #{map-get($header-height, sm)}px;
    }
   	@include media-breakpoint-up(md) {
       	padding-top: #{map-get($header-height, md)}px;
   	}
  	@include media-breakpoint-up(lg) {
       	padding-top: #{map-get($header-height, lg)}px;
   	}
   	@include media-breakpoint-up(xl) {
       	padding-top: #{map-get($header-height, xl)}px;
   	}
   	@include media-breakpoint-up(xxl) {
       	padding-top: #{map-get($header-height, xxl)}px;
   	}
    */
}

/* .backdrop */
.backdrop {
    @extend .modal-backdrop;
    background-color: $white;

    &.navi-backdrop {
        z-index: ($zindex-fixed - 5);
        background-color: $black;
    }
}

/* .page > .main > .section */
.page {
    overflow: hidden;
    background-color: $body-bg;
}

    .main {
        background-color: $body-bg;
    }
    .main > .section-hero {
        position: relative;
        z-index: 1;
        overflow: hidden;
        background-color: inherit;
    }
    .main > .section-before-content {
        position: relative;
        z-index: 10;
        background-color: inherit;
    }
    .main > .section-content {
        position: relative;
        z-index: 10;
        background-color: inherit;
    }
    .main > .section-after-content {
        position: relative;
        z-index: 10;
        background-color: inherit;
    }

    .footer {
        position: relative;
				background-color: $secondary;
				color: $light;
        .footer-1 {
						padding-top: 1rem;
						padding-bottom: 1rem;
        }
				.footer-2 {
            padding-top: 1rem;
						padding-bottom: 1rem;
            font-size: 80%;
        }
    }


    .section-hero > .svg-container {
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: auto;

        &.wave-1-container {
            bottom: -15%;
            @include media-breakpoint-up(sm) {
                bottom: -35%;
            }
            @include media-breakpoint-up(md) {
                bottom: -45%;
            }
            @include media-breakpoint-up(lg) {
                bottom: -55%;
            }
            @include media-breakpoint-up(xl) {
                bottom: -30%;
            }
        }
        &.wave-2-container {
            bottom: -10%;
            @include media-breakpoint-up(sm) {
                bottom: -5%;
            }
            @include media-breakpoint-up(md) {
                bottom: 0%;
            }
            @include media-breakpoint-up(lg) {
                bottom: 5%;
            }
            @include media-breakpoint-up(xl) {
                bottom: 10%;
            }
            opacity: .35;
        }
        &.wave-3-container {
            bottom: -10%;
            @include media-breakpoint-up(sm) {
                bottom: -30%;
            }
            @include media-breakpoint-up(md) {
                bottom: -40%;
            }
            @include media-breakpoint-up(lg) {
                bottom: -50%;
            }
            @include media-breakpoint-up(xl) {
                bottom: -25%;
            }
            opacity: .5;
        }
    }

    .sidebar {
        position: relative;
        .sidebar-inner {
            transition: top .5 ease-out;
            position: relative;
            top: 0;
            &.stuck {
                position: fixed;
                top: 120px;
            }
        }
    }

.blog {

}

/* Equalize grid-blog spacing */
$gutter-width: 1px;
.grid-blog {
       padding-left: 0;
      padding-right: 0;
        padding-top: $gutter-width;
     padding-bottom: $gutter-width;
    .row {
           margin-left: -$gutter-width;
          margin-right: -$gutter-width;
        .item {
            padding-left: $gutter-width;
           padding-right: $gutter-width;
              margin-top: $gutter-width;
           margin-bottom: $gutter-width;
        }
    }
}

/*
*  # HEADER
*  --------------------------  */

/* Header Container */
.header {
    height: #{map-get($header-height, xs)}px;
    max-width: $viewport-max-width;
    margin: 0 auto;
    @include media-breakpoint-up(sm) {
        height: #{map-get($header-height, sm)}px;
    }
    @include media-breakpoint-up(md) {
        height: #{map-get($header-height, md)}px;
    }
    @include media-breakpoint-up(lg) {
        height: #{map-get($header-height, lg)}px;
    }
    @include media-breakpoint-up(xl) {
        height: #{map-get($header-height, xl)}px;
    }

    @include header-bg();

    > .navbar {
        height: inherit;
        position: relative;
        max-width: $viewport-max-width;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;

        /* Navbar Toggle Button */
        .navbar-toggler {
            position: absolute;
            left: 50%;
            top: 28px;
            padding: 0;
            border: none;
            width: 24px;
            height: 24px;
            margin-left: -12px;
            margin-top: -12px;
            @include media-breakpoint-up(sm) {
                top: 32px;
                width: 38px;
                height: 38px;
                margin-left: -19px;
                margin-top: -19px;
            }
            > .show-navi {
                @include svg('hamburger', 1, 24, 24);
                @include media-breakpoint-up(lg) {
                    @include svg('hamburger', 1);
                    display: none;
                }
                display: none;
            }
            > .hide-navi {
                @include svg('close-button', 1, 24, 24);
                @include media-breakpoint-up(lg) {
                    @include svg('close-button', 1);
                    display: inline-block;
                }
                display: inline-block;
            }
            &.collapsed {
                > .show-navi {
                    display: inline-block;
                }
                > .hide-navi {
                    display: none;
                }
            }
            @include kill-tab-focus();
        }

        .navbar-collapse {
            padding-top: 1rem;
            padding-bottom: 2.5rem;
        }

        @include media-breakpoint-down(md)
        {
            align-items: flex-start;
            .navbar-collapse {
                padding-top: 100px;
                padding-bottom: 0;
                min-height: 568px;
            }
        }
    }
}

 /* Logo – navbar-brand */
 .navbar-brand {
    padding: 12px 0;
    height: #{map-get($header-height, xs)}px;
    .logo { @include svg($logo, 1, auto, (map-get($header-height, xs) - 24)); }
    @include media-breakpoint-up(sm) {
        height: #{map-get($header-height, sm)}px;
        .logo { @include svg($logo, 1, auto, (map-get($header-height, sm) - 24)); }
    }
    @include media-breakpoint-up(md) {
        height: #{map-get($header-height, md)}px;
        .logo { @include svg($logo, 1, auto, (map-get($header-height, md) - 24)); }
    }
    @include media-breakpoint-up(lg) {
        height: #{map-get($header-height, lg)}px;
        .logo { @include svg($logo, 1, auto, (map-get($header-height, lg) - 24)); }
    }
    @include media-breakpoint-up(xl) {
        height: #{map-get($header-height, xl)}px;
        .logo { @include svg($logo, 1, auto, (map-get($header-height, xl) - 24)); }
    }
    @include media-breakpoint-up(xxl) {
        height: #{map-get($header-height, xxl)}px;
        .logo { @include svg($logo, 1, auto, (map-get($header-height, xxl) - 24)); }
    }
}

.header > .navbar {
    background-color: transparent;
}

/*
 *  # NAVS
 *  --------------------------  */

/* Main Navi */
.navbar-nav {
    font-family: $font-family-sans-serif;
    margin-top: .5rem;
    text-transform: uppercase;
    padding-left: (.5 * $grid-gutter-width);
    padding-right: (.5 * $grid-gutter-width);
    font-size: 1.75rem;
    text-align: center;
    @include media-breakpoint-up(sm) {
        font-size: 1.65rem;
    }
    @include media-breakpoint-up(md) {
        font-size: 1.55rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 1.1rem;
        text-align: left;
    }
    @include media-breakpoint-up(xl) {
        font-size: 1.2rem;
    }
    @include media-breakpoint-up(xxl) {
         font-size: 1.2rem;
    }

    .nav-item > .nav-link {
        @include media-breakpoint-up(lg) {
            padding-right: 2.5rem;
            padding-left: 0;
        }
        color: $primary;
        position: relative;
        transition: color .3s linear;
        @include kill-tab-focus();

        &.disabled,
        &:disabled {
            cursor: not-allowed;
            color: $text-muted;
        }

        @include hover-focus {
          	text-decoration: none;
            &:not(.disabled),
            &:not(:disabled) {
                color: rgba($white,.75);
            }
        }
    }
    .active > .nav-link,
    .nav-item > .nav-link.active {
         color: $white;
    }
    .nav-item.hover > .nav-link {
        color: rgba($white,.75);
    }
    .nav-item.hover > .dropdown-menu {
        display: block;
    }
}

//  Dropdown
.dropdown-menu {
    .dropdown-item {
        text-transform: none;
        @include kill-tab-focus();
    }
}

/* Other Navs */
.nav {
    & {
        .nav-item {

        }
        .nav-link {
            font-size: $font-size-base;
            letter-spacing: .05rem;
            text-transform: uppercase;
            transition: color .2s linear;
            @include hover-focus() {

            }
            @include media-breakpoint-down(md)   {
                font-size: 85%;
                padding: .5rem .75rem;
            }
        }
    }

    // Footer nav
    &.footer-nav {
        justify-content: flex-end;
        .nav-link {
            color: $white;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            font-size: 80%;
            &.active {
                color: $primary;
            }
        }
    }
}

//  Pagenav
//  !INFO! Html for pagenavigation is here: [template]/html/plg_content_pagenavigation/default.php
.pagenav {
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: $zindex-fixed;
    @include media-breakpoint-up(lg) {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    @include media-breakpoint-up(xl) {
        padding-left: 4.625rem;
        padding-right: 4.625rem;
    }
    @include media-breakpoint-up(xxl) {
        left: auto;
        right: auto;
        padding-left: 0;
        padding-right: 0;
        max-width: 1642px;
    }

    > .previous,
    > .next {
				list-style-type: none;
				> .nav-link {
						padding: 0;
						width: 32px;
						height: 32px;
						border-radius: 16px;
						margin-top: -16px;
						background-color: rgba($white,.75);
						@include media-breakpoint-up(lg) {
								width: 64px;
								height: 64px;
								border-radius: 32px;
								margin-top: -32px;
								background-color: rgba($white,0);
						}
				}
    }
		> .previous > .nav-link {
				margin-right: auto;
				@include media-breakpoint-up(lg) {
				   	margin-left: 8px;
				}
				@include media-breakpoint-down(md) {
				   	transition: margin-left .3s ease-out;
				   	margin-left: -64px;
				}
		}
		> .next  > .nav-link {
		   	margin-left: auto;
		   	@include media-breakpoint-up(lg) {
		       	margin-right: 8px;
		   	}
		   	@include media-breakpoint-down(md) {
		       	transition: margin-right .3s ease-out;
		       	margin-right: -64px;
		   	}
		}
}

.scrolled-past-header .pagenav {
    @include media-breakpoint-down(md)
    {
        > .previous > .nav-link {
            margin-left: -8px;
        }
        > .next > .nav-link {
            margin-right: -8px;
        }
    }
}

.brand {
    @include svg('logo-wittler', .5);
}

.brand-lg {
    @include svg('logo-wittler', .5);
    @include media-breakpoint-up(sm)
    {
        @include svg('logo-wittler', .55);
    }
    @include media-breakpoint-up(md)
    {
        @include svg('logo-wittler', .6);
    }
    @include media-breakpoint-up(lg)
    {
        @include svg('logo-wittler', .75);
    }
    @include media-breakpoint-up(xl)
    {
        @include svg('logo-wittler', 1);
    }
}

.brand-white {
    .brand-lg {
        @include svg('logo-wittler-white', .5);
        @include media-breakpoint-up(sm)
        {
            @include svg('logo-wittler-white', .55);
        }
        @include media-breakpoint-up(md)
        {
            @include svg('logo-wittler-white', .6);
        }
        @include media-breakpoint-up(lg)
        {
            @include svg('logo-wittler-white', .75);
        }
        @include media-breakpoint-up(xl)
        {
            @include svg('logo-wittler-white', 1);
        }
    }
}

/*
 *  # BUTTONS
 *  --------------------------  */

/* Scroll-to-top button */
.scroll-to-top {
		transition: bottom .3s ease-out;
		position: fixed;
		width: 64px;
		height: 64px;
		border-radius: 32px;
		margin-left: -32px;
		margin-top: -32px;
		background-color: rgba($white,.75);
		right: 64px;
		bottom: -64px;
		z-index: $zindex-fixed;
		@include media-breakpoint-down(md)
		{
				width: 32px;
				height: 32px;
				border-radius: 16px;
				margin-left: -16px;
				margin-top: -16px;
				right: 20px;
				bottom: -32px;
		}
}

.scrolled-past-header .scroll-to-top {
    bottom: 64px;
    @include media-breakpoint-down(md)
    {
        bottom: 32px;
    }
}

/*
*  # MODULES
*  --------------------------  */

.module-title {
		text-transform: uppercase;
		color: $primary;
		font-size: $h1-font-size;
		letter-spacing: .05rem;
}


//  Maps Module
.g-maps-module {
    position: relative;
    min-height: 480px;
    .layer-card {
        position: absolute;
        top: auto;
        left: 10px;
        bottom: 10px;
        right: 10px;
        background-color: transparent;
        border: none;
    }

    .ui-panel {
        position: absolute;
        .icon {
            cursor: pointer;
            color: $primary;
            transition: color .3s ease;
            @include hover-focus() {
                color: darken($primary, 15%);
            }
            &:before {
                margin: 0;
                font-size: 150%;
            }
        }
        > .icon-hair-cross:before {
            font-size: 140%;
        }

        &.ui-panel-top-right {
            width: 32px;
            height: auto;
            left: auto;
            right: 1.5rem;
            top: 1.5rem;
        }

        &.ui-panel-bottom-center {
            width: auto;
            height: auto;
            left: 0;
            right: 0;
            top: auto;
            bottom: 0;
            background-color: rgba($white,.5);
            padding-top: 1rem;
        }
    }

    .map-container > .map-canvas
    {
        width: inherit;
        height: inherit;
    }

    .input-group {
       .input-group-prepend {
            .input-group-text {
                border-right-color: transparent;
                background-color: $input-bg;
                color: $primary;
            }
            + .form-control {
                border-left-color: transparent;
            }
        }
    }

    .btn-group > .btn,
    .btn-group > .btn:not(:last-child):not(.dropdown-toggle)
    {   // make separated round buttons in button-groups
        padding: 6px;
        height: 38px;
        @include border-left-radius(50%);
        @include border-right-radius(50%);
        margin-right: .5rem;
    }
}

.bg-img
{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

 /*
  *  # COMPONENTS
  *  --------------------------  */

.page-title,
.item-title {
    text-transform: uppercase;
}

.page-title {
    color: $primary;
}

 //  Blog featured
.blog-featured {
    .item {

    }
}

/*
 *  # UTILS
 *  --------------------------  */
.bg-crosses {
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        width: 100%;
        height: 100%;
        @include bg-crosses(transparent, rgba($secondary,.15));
    }
}

.spacer {
   	display: block;
   	width: 100%;

   	&.spacer-big { padding-top: 50px; }
   	&.spacer-10  { padding-top: 10px; }
   	&.spacer-20  { padding-top: 20px; }
   	&.spacer-25  { padding-top: 25px; }
   	&.spacer-30  { padding-top: 30px; }
   	&.spacer-40  { padding-top: 40px; }
   	&.spacer-50  { padding-top: 50px; }
   	&.spacer-60  { padding-top: 60px; }
   	&.spacer-70  { padding-top: 70px; }
   	&.spacer-75  { padding-top: 75px; }
   	&.spacer-80  { padding-top: 80px; }
   	&.spacer-90  { padding-top: 90px; }
   	&.spacer-100 { padding-top: 100px; }
}

// MEDIA QUERIES (samples)
@include media-breakpoint-up(xs)   { }
@include media-breakpoint-up(sm)   { }
@include media-breakpoint-up(md)   { }
@include media-breakpoint-up(lg)   { }

@include media-breakpoint-down(sm) { }
@include media-breakpoint-down(md) { }
@include media-breakpoint-down(lg) { }
@include media-breakpoint-down(xl) { }
